<template>
	<div>
		<div
			class="container-fluid"
			id="foot"
		>
			<div class="container footer-box">
				<div class="fl">
					<!-- <div>面试小助手人工智能科技（成都）有限公司</div> -->
					<div>盛世创客（成都）人力资源管理有限公司</div>

					<!-- <p>地址：四川省成都市天府新区天府大道南段2039号天府菁蓉大厦209</p> -->
					<p>地址：成都市武侯区致名路34号附25号1栋1层002号</p>

					<p>电话：13811132498</p>
					<!-- <div class="code">
						<div class="codebox">
							<img src="@/assets/image/code1.jpg" />
							<p>小程序</p>
						</div>
						<div class="codebox">
							<img src="@/assets/image/app_code.png" />
							<p>App</p>
						</div>
					</div> -->
				</div>

				<div style="margin-right: 240px">
					<div class="mid">
						<div>企业介绍</div>
						<a href="/list4.html">品牌介绍</a>
						<a href="/list4.html">团队介绍</a>
						<a href="/list4.html">关于我们</a>
					</div>

					<div class="contact">
						<div>服务范围</div>
						<ul>
							<li
								class="ft1"
								@click="$router.push('/template')"
							>
								产品介绍
							</li>
							<!-- <li
								class="ft2"
								@click="$router.push('/list3.html')"
							>
								热招岗位
							</li> -->
							<li
								class="ft2"
								@click="$router.push('/list2.html')"
							>
								学员案例
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<footer class="container-fluid">
			<a
				href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
				target="_blank"
				>蜀ICP备2021001179号-1</a
			>
			<!-- <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow"
        >蜀ICP备2021001179号</a
      > -->
		</footer>
	</div>
</template>

<script>
export default {};
</script>

<style
	lang="less"
	scoped
>
.container-fluid {
	min-width: 1200px;
}
/* 版心 */
.container {
	width: 1200px;
	margin: 0 auto;
}
#foot {
	background: #2e3036;

	padding: 20px 0;
}

#foot .fl {
	width: 500px;
	color: #fff;
}

#foot .fl div {
	font-weight: bold;
	font-size: 16px;
}

#foot .fl p {
	margin-top: 20px;
	font-size: 12px;
	line-height: 200%;
}

#foot .mid {
	width: 100px;
	margin-left: 40px;
	color: #fff;
	float: left;
}

#foot .mid div {
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 15px;
}

#foot .mid a {
	color: #fff;
	display: block;
	font: 12px/30px "微软雅黑";
}

#foot .contact {
	margin-left: 40px;
	color: #fff;
	float: left;
}

#foot .contact div {
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 15px;
}

#foot .contact li {
	color: #fff;
	height: 30px;
	line-height: 30px;
	font-size: 12px;
	list-style: none;
	cursor: pointer;
}

#foot .code {
	display: flex;

	font-size: 12px;
	margin-top: 40px;
	color: #fff;
	.codebox {
		width: 120px;

		margin-right: 30px;
		p {
			text-align: center;
		}
	}
}

#foot .code img {
	display: block;
	width: 100%;
	height: 120px;
	margin-bottom: 4px;
	margin-right: 14px;
}

footer {
	background: #25282d;
	padding: 10px;
	text-align: center;
	color: #fff;
	font-size: 12px;

	a {
		color: #999;
	}
}
.footer-box {
	display: flex;
	justify-content: space-between;
}
</style>
