<template>
	<div class="top container-fluid">
		<div class="container header-act dpflex">
			<div>
				<img
					src="@/assets/image/logo.png"
					@click="$router.push('/')"
					alt=""
				/>
			</div>
			<div class="dpflex">
				<router-link
					:class="{ page: true, actcolor: this.$route.name == 'index' }"
					:to="{ path: '/' }"
					>首页</router-link
				>

				<router-link
					:class="{ page: true, actcolor: this.$route.name == 'Template' }"
					:to="{ path: '/template' }"
					>产品服务
				</router-link>

				<router-link
					:class="{
						page: true,
						actcolor: this.$route.name == 'list2',
					}"
					:to="{ path: '/list2.html' }"
					>合作案例</router-link
				>
				<!-- <router-link
          :class="{
            page: true,
            actcolor: this.$route.name == 'list3',
          }"
          :to="{ path: '/list3.html' }"
          >热招职位</router-link
        >
        <router-link
          :class="{
            page: true,
            actcolor: this.$route.name == 'new',
          }"
          :to="{ path: '/new' }"
          >热点资讯</router-link
        > -->
				<router-link
					:class="{
						page: true,
						actcolor: this.$route.name == 'list4',
					}"
					:to="{ path: '/list4.html' }"
					>关于我们</router-link
				>
				<div @click="handBtn"
				class="dpflex regbox"
				style="cursor: pointer;padding: 10px;margin-left: 10px;"
				>
					进入企业后台
				</div>
				<!-- <router-link  :class="{ page: true, actcolor: this.$route.name == 'index' }" :to="{ name: 'page3', params: { id: 3306 } }"
          >优化简历</router-link
        > -->
			</div>
			<!-- <div
				v-if="user.token == undefined"
				class="dpflex regbox"
			>
				<router-link
					class="page"
					to="/login"
					>登录</router-link
				>/
				<router-link
					class="page"
					to="/register"
					>注册</router-link
				>
			</div>
			<div
				v-else
				class="dpflex"
			>
				<router-link
					class="page"
					to="/personal"
					>个人中心</router-link
				>
				<p
					class="page"
					@click="edit"
				>
					退出
				</p>
			</div> -->
		</div>
	</div>
</template>

<script>
import { getUser, removeUser } from "@/plugins/auth.js";

export default {
	data() {
		return {
			routeParams: this.$route.params,
			user: "",
		};
	},
	created() {
		console.log(this.$route.name);
		this.user = getUser();
		// console.log(user, "head");
		// return { user: user };
		// // 客户端代码
		//  this.$store.commit("userstore/setUserInfo", this.store);
		// }
	},
	watchQuery: ["id"],
	watch: {
		$route: {
			handler: function (val) {
				// console.log(val.params);
				Object.assign(this.routeParams, val.params);
			},
			// 深度观察监听
			deep: true,
		},
	},
	methods: {
		handBtn(){
			window.open('http://web.manager.b.hrclass.com.cn/')
		},
		edit() {
			// console.log(this.$store.state.userstore.userInfo, "之前");
			// console.log(1);
			// this.$store.commit("userstore/setUserInfo", null);
			// console.log(this.$store.state.userstore.userInfo, "之后");
			removeUser();

			this.$router.push("/login");

			console.log(getUser(), "editcookie");
		},
	},
	computed: {
		info() {
			return this.$store.state.userstore.userInfo;
		},
	},
};
</script>

<style lang="less">
.container-fluid {
	min-width: 1200px;
}

.regbox {
	height: 30px;
	font-size: 12px;

	background: #2a84e0;
	border-radius: 4px 4px 4px 4px;
	white-space: nowrap;
	display: flex;
	align-items: center;
	color: #ffffff;

	.page {
		display: inline-block;
		font-size: 12px !important;
		color: #ffffff !important;
		font-weight: 500;
		line-height: 30px;
	}
}

/* 版心 */
.container {
	width: 1200px;
	margin: 0 auto;
}

.top {
	// -background-color: #5751fb;
	height: 60px;

	.header-act {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		img {
			width: 145px;
			height: 35px;
		}

		.page {
			padding: 0 15px;
			color: rgba(0, 0, 0, 0.7);
			font-size: 16px;
			// text-decoration: none;
		}

		.actcolor {
			color: #5f9eff !important;
			font-weight: bold;
		}

		.page:hover {
			color: #000000;
		}

		.dpflex {
			display: flex;
			align-items: center;
		}
	}
}
</style>
