import Cookies from 'js-cookie'
const TokenKey = 'User‐Token'
const AvatarKey = 'User‐AvatarKey'
const MobileKey = 'User‐Mobile'
const IdKey = 'User‐Id'
const nickNameKey = 'User‐nickName'
const filePathKey = 'User‐filePath'

export function setUser(token, id, avatar, mobile, nickname) {
    Cookies.set(IdKey, id, { expires: 1 / 6 })
    Cookies.set(TokenKey, token, { expires: 1 / 6 })
    Cookies.set(AvatarKey, avatar, { expires: 1 / 6 })
    Cookies.set(MobileKey, mobile, { expires: 1 / 6 })
    Cookies.set(nickNameKey, nickname, { expires: 1 / 6 })

}


export function getUser() {
    return {
        token: Cookies.get(TokenKey),
        id: Cookies.get(IdKey),
        avatar: Cookies.get(AvatarKey),
        mobile: Cookies.get(MobileKey),
        nickname: Cookies.get(nickNameKey),

    }
}

export function removeUser() {

    Cookies.remove(IdKey)
    Cookies.remove(TokenKey)
    Cookies.remove(AvatarKey)
    Cookies.remove(MobileKey)
    Cookies.remove(nickNameKey)

}

export function setfile(filePath) {
    Cookies.set(filePathKey, filePath,{ expires: 1 / 6 })

}
export function getfile() {
    return {
        filePath: Cookies.get(filePathKey)
    }
}

export function removefile() {
    Cookies.remove(filePathKey)
}