import axios from "@/plugins/untils.js";



// /* 简历列表 */
// export const api_queryHrResumeTemplateList = (params) => {
//     return axios.post('/web/queryNewsIndex', params)
// }

/* 登录 */
export const api_authLogin = (params) => {
    return axios.post('/web/login/authLogin', params)
}

/* 登录 */
export const api_queryWxInfo = (params) => {
    return axios.get('/web/login/queryWxInfo', { params })
}



/* 注册新账号 */
export const api_saveUserInfo = (params) => {
    return axios.post('/web/user/saveUserInfo', params)
}

/* 发送验证码 */
export const api_sendMsg = (params) => {
    return axios.post('/web/msg/sendMsg', params)
}
/* 获取个人信息 */
export const api_queryUser = (params) => {
    return axios.get('/web/main/queryUser', { params })
}
/* 保存修改个人密码 */
export const api_updateUser = (params) => {
    return axios.post('/web/user/updateUser', params)
}

/* 保存修改个人密码 */
export const api_updateUserInfo = (params) => {
    return axios.post('/web/login/updateUserInfo', params)
}
/* 保存修改个人头像 */
export const api_updateUserImg = (params) => {
    return axios.post('/web/user/updateUserImg', params)
}


/* 简历诊断 */
export const api_intelligentResumeDiagnosis = (params) => {
    return axios.post('/web/fileTxtData//intelligentResumeDiagnosis', params)
}

/* 简历诊断记录 */
export const api_queryResumeInfoList = (params) => {
    return axios.post('/web/university/index/queryResumeInfoList', params)
}



/* 新闻列表 */
export const api_queryNewsTitleList = (params) => {
    return axios.post('/web/seo/child/queryNewsTitleList', params)
}



/* 新闻分类 */
export const api_queryNesCategory = (params) => {
    return axios.post('/web/seo/child/queryNesCategory', params)
}

/* 新闻详情 */
export const api_queryNewsDetail = (params) => {
    return axios.get('/web/seo/child/queryNewsDetail', { params })
}

/* ========================简历模板============================ */

/* 简历模板列表 */
export const api_queryResumeTemplateList = (params) => {
    return axios.post('/web/seo/child/queryResumeTemplateList', params)
}

/* 简历模板分类 */
export const api_queryResumeTemplateTypeList = (params) => {
    return axios.get('/web/seo/child/queryResumeTemplateTypeList', { params })
}

/* 简历模板详情 */
export const api_queryResumeTemplateDetail = (params) => {
    return axios.get('/web/seo/child/queryResumeTemplateDetail', { params })
}


/* 订单新增 */
export const api_saveHrOrder = (params) => {
    return axios.post('/web/order/saveHrOrder', params)
}

/* 订单列表 */
export const api_queryResumeOrderList = (params) => {
    return axios.post('/web/seo/child/queryResumeOrderList', params)
}



/*  新增模板下载次数*/
export const api_addRusumeDownNum = (params) => {
    return axios.get('/web/seo/child/addRusumeDownNum', { params })
}

/*  查询是否支付完成*/
export const api_queryHrOrder = (params) => {
    return axios.post('/web/order/queryHrOrder', params)
}
/* 取消订单 */
export const api_updateHrOrder = (params) => {
    return axios.post('/web/order/updateHrOrder', params)
}





/* 芯片列表 */
export const api_v1queryProductCList = (params) => {
    return axios.post('/web/V1/product/queryProductCList', params)
}

/* 芯片详情 */
export const api_v1queryProduct = (params) => {
    return axios.get('/web/V1/product/queryProduct', {params})
}



/* ===========================================招聘岗位===================================== */

/* 行业分类 */
export const api_queryHyType = (params) => {
    return axios.post('/web/employ/queryHyType', params)
}

/* 岗位分类 */
export const api_queryJobType = (params) => {
    return axios.post('/web/employ/queryJobType ', params)
}

 

/* 岗位列表 */
export const api_queryJobList = (params) => {
    return axios.post('/web/employ/queryJobList', params)
}
/* 岗位详情 */
export const api_queryJobDetail = (params) => {
    return axios.post('/web/employ/queryJobDetail', params)
}


/* 简历投递 */
export const api_send = (params) => {
    return axios.post('/web/resumeDelivery/send', params)
}

/* 简历投递记录 */
export const api_records= (params) => {
    return axios.post('/web/resumeDelivery/records', params)
}
/* 获取地址 */
export const api_getConLat= (params) => {
    return axios.post('/web/employ/getConLat', params)
}

/* 查询简历 */
export const api_webqueryUser= (params) => {
    return axios.get('/web/user/queryUser', {params})
}



/* ces */
export const queryCompanyList= (params) => {
    return axios.post('/api/company/queryCompanyList', params)
}

