<template>
  <div class="container-fluid">
    <Heard />
    <div class="banner-box container-fluid">
      <el-carousel trigger="click" height="400px" >
        <el-carousel-item v-for="item in bannerlist" :key="item.id" style="display: flex;">
          <img
            class="container-fluid"
            style="margin: 0 auto !important;"
            :src="item.src"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="indebox">
      <img class="container-fluid" src="@/assets/image/indexcont.png" alt="" />
    </div>
    <!-- <div class="comtitle container">讲师介绍</div> -->

    <!-- <div class="tec-box container">
      <el-carousel trigger="click" height="440px" :interval="5000">
        <el-carousel-item v-for="item in teclist" :key="item.id">
          <img :src="item.src1" alt="" />
          <img :src="item.src2" alt="" />
          <img :src="item.src3" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <div class="container-fluid bgcfoot">
      <div class="container footbox">
        <div class="left">
          <p>成为助力中国高端产业升级的优秀企业</p>
          <div class="desc">
            以企业岗位需求为导向，提供贴合企业环境的培训+实战平台，让学员符合企业需求，且直接就业！
          </div>
        </div>
        <div class="right" @click="$router.push('/template')">了解更多</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { setfile, setUser, getUser } from "@/plugins/auth.js";

import {
  api_queryWxInfo,
  api_authLogin,
  api_queryResumeTemplateList,
  // queryCompanyList,
} from "@/apis/apis";
import Heard from "@/components/Heard.vue";

import Footer from "@/components/Footer.vue";

export default {
  components: {
    Heard,

    Footer,
  },
  // layouts: "index",
  data() {
    return {
      namelist: [],
      page: 1,
      token: "",

      bannerlist: [
        { id: 30, src: require("@/assets/image/bannerindex-1.jpg") },
        { id: 34, src: require("@/assets/image/bannerindex-2.jpg") },
      ],
      teclist: [
        {
          id: 1,
          src1: require("@/assets/image/tec1.png"),
          src2: require("@/assets/image/tec2.png"),
          src3: require("@/assets/image/tec3.png"),
        },
        {
          id: 2,
          src1: require("@/assets/image/tec4.png"),
          src2: require("@/assets/image/tec5.png"),
          src3: require("@/assets/image/tec6.png"),
        },
        {
          id: 3,
          src1: require("@/assets/image/tec7.png"),
          src2: require("@/assets/image/tec8.png"),
          src3: require("@/assets/image/tec9.png"),
        },
      ],
      fileList: [],
      file: [],
      curindex: 1,
      mantle: false,
      code: "",
      openId: "",
      unionid: "",
      user: "",
      list: [],
    };
  },

  async created() {
    this.user = getUser();
    // queryCompanyList({}).then((res) => {
    //   console.log(res);
    // });
    //  console.log(this.user);
    let res = await api_queryResumeTemplateList({
      currPage: 1,
      // title: this.title, //查询条件
      orderType: 0, //不传 默认最新，0 ：最新 1：最热
      pageSize: 4,
    });
    this.list = res.data.data.dataList;

    if (this.code != "") {
      api_queryWxInfo({
        code: this.code,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          this.openId = res.data.openid;
          this.unionid = res.data.unionid;
          api_authLogin({
            openId: this.openId,
            loginType: 2, // 0: 账号密码登  ，1：短信验证码登录 2微信登录
          }).then((res) => {
            console.log(res);
            if (res.data.status === true) {
              setUser(
                res.data.data.token,
                res.data.data.user.id,
                res.data.data.user.avatar,
                res.data.data.user.mobile,
                res.data.data.user.nickName
              );
              this.$router.push("/");
            }
          });
        }
      });
    }
  },
  methods: {
    // clickbanner(id) {
      // this.$router.push(`/template/${id}`);
    // },
    tab_menu(item) {
      this.curindex = item.id;
    },
    //文件上传成功
    fileupload(response, file, fileList) {
      let oldarr = [];
      fileList.forEach((item) => {
        if (!oldarr.includes(item.response.data.url)) {
          oldarr.push(item.response.data.url);
        }
      });
      this.file = oldarr;
      console.log(this.file, "赋值后");
    },

    handleRemove(file, fileList) {
      let oldarrremove = [];
      fileList.forEach((item) => {
        if (!oldarrremove.includes(item.response.data.url)) {
          oldarrremove.push(item.response.data.url);
        }
      });
      this.file = oldarrremove;
      console.log(this.file, "赋值后");
    },

    diagnow() {
      if (this.file != "" && this.user.token != undefined) {
        this.mantle = false;
        this.$router.push("diag");
        setfile(this.file);
      } else if (this.user.token == undefined) {
        alert("请先登录！！！");
        this.$router.push("/login");
      } else {
        alert("请先上传简历");
      }
    },
    end() {
      var y = document.getElementById("test");
      y.style.animationPlayState = "paused";
    },
    start() {
      var y = document.getElementById("test");
      y.style.animationPlayState = "running";
    },
    clickalert() {
      console.log(1111);
    },
  },
  // async asyncData({ ctx, context, route, params }) {
  //   let res = await api_queryResumeTemplateList({
  //     currPage: 1,
  //     // title: this.title, //查询条件
  //     orderType: 0, //不传 默认最新，0 ：最新 1：最热
  //     pageSize: 4,
  //   });
  //   console.log(res.data, "1212312312312");
  //   return {
  //     list: res.data.data.dataList,
  //   };
  // console.log(context);
  // console.log(store);
  //console.log(ctx.store.state.userstore.userInfo);
  // console.log(context, "context");
  // console.log(route.query.code, "code");
  // let code = route.query.code;
  // if (code) {
  //   api_queryWxInfo({
  //     code,
  //   }).then((res) => {
  //     console.log(res);
  //   });
  // }
  // console.log(params, "params");
  // let res = await api_queryHrResumeTemplateList({});
  // return { namelist: res.data.data.news };
  // },

  // actions: {
  //   nuxtServerInit({ commit }, { req }) {
  //     console.log(commit, req, "23424");
  //     if (req.session.user) {
  //       commit("user", req.session.user);
  //     }
  //   },
  // },
  // computed: {
  //   /* 接收参数 */
  //   xxx() {
  //     console.log(this.$store.state.userinfo);
  //   },
  // },
};
</script>

<style  lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.indebox {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    -height: 3175px;
  }
}
.bgcfoot {
  margin-top: 100px;
  height: 200px;
  background: url(~@/assets/image/bgcfoot.png) no-repeat center;
  background-size: 100% 100%;
  color: #fff;
  .footbox {
    display: flex;
    justify-content: space-around;
    .left {
      p {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 36px;
        margin-top: 42px;
      }
      .desc {
        width: 520px;
        font-size: 16px;
        font-weight: 300;
        line-height: 34px;
      }
    }
    .right {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #ffffff;
      margin-top: 110px;
    }
  }
}
a {
  text-decoration: none;
}

ul li {
  list-style: none;
}
.container-fluid {
  min-width: 1200px;
  margin: 0 auto;
}
/* 版心 */
.container {
  width: 1200px;
  margin: 0 auto;
}
.banner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-carousel {
    width: 100%;
    min-width: 1200px;

    img {
      min-width: 1200px;
      height: 400px;
    }
  }
}
.tec-box {
  .el-carousel {
    :nth-child(1) {
      margin-left: 20px;
    }
    :nth-child(2) {
      margin: 0 80px;
    }
    img {
      display: inline-block;
      width: 320px;
      height: 440px;
    }
  }
}
.comtitle {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 22px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #000000;
}
.allresitem {
  padding: 26px 40px;
  .title {
    display: flex;
    margin-top: 80px;
    justify-content: space-between;
    margin-bottom: 60px;

    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
    }
    .more {
      font-size: 18px;
      font-weight: 400;
      color: #7b7b7b;
    }
  }
  .resitem-box {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    .everitem {
      width: 240px;
      height: 407px;
      -border: 1px solid #ccc;
      .imgbox {
        width: 240px;
        height: 320px;
        margin-bottom: 12px;
        border: 1px solid #ccc;

        overflow: hidden;
        img {
          width: 240px;
        }
      }

      .content {
        .cont-title {
          font-size: 14px;
          font-weight: bold;
          color: #000000;
        }
        .name {
          font-size: 12px;
          font-weight: 400;
          color: #7b7b7b;
          margin: 4px 0 12px 0;
          display: block;
        }
        .num {
          font-size: 12px;
          font-weight: 400;
          color: #7b7b7b;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #000;
          }
          .btn {
            width: 90px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            color: #fff;
            background: #5751fb;
          }
        }
      }
    }
  }
}
#ser {
  margin-top: 80px;

  .name {
    color: #313051;
    font-size: 30px;
    text-align: center;
    line-height: 50px;
  }
  ul {
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: space-around;
  }

  li {
    transition: all 0.5s ease 0s;
    border-radius: 8px;
    overflow: hidden;
    width: 386px;

    text-align: center;
    padding: 40px 0;
    margin: 40px 0;
    cursor: pointer;
  }
  li:hover {
    box-shadow: 0 2px 20px 0 rgba(228, 228, 228, 0.5);
    border-color: #fff;
  }
  li div {
    font-weight: bold;
    font-size: 20px;
    color: #000;
    margin: 0px 0 0px 0;
  }
  li p {
    font-size: 18px;
    margin-top: 8px;
  }
  li img {
    display: block;
    margin: 0px auto 20px auto;
  }
}
#data {
  height: 380px;
  background: url(~@/assets/image/data.png) center;
  margin-top: 40px;
  color: #fff;
  text-align: center;
  .u1 {
    padding-top: 70px;
    display: flex;
    justify-content: space-around;
  }

  .u1 li {
    .per {
      font-size: 52px;
    }
    .bd {
      width: 80px;
      height: 3px;
      background: #fff;
      margin: 20px auto;
    }
    .f18 {
      font-size: 18px;
    }
  }

  .u2 {
    width: 80%;
    margin: 40px auto 0 auto;
    display: flex;
    li {
      width: 33.33333%;
      .per {
        font-size: 28px;
      }
      .f18 {
        font-size: 16px;
      }
    }
  }
}
#prob {
  padding-top: 40px;
  background: #eee;
  .name {
    color: #313051;
    font-size: 30px;
    text-align: center;
  }
  #tab1 {
    width: 100%;
    margin-top: 40px;
    .tab_menu {
      height: 42px;
      border-bottom: 1px solid #ccc;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .current {
        border-bottom: 2px solid #5e58fb;
      }
      li {
        display: flex;
        justify-content: center;
        height: 42px;
        font: 20px/42px "微软雅黑";
        color: #53526e;
        text-align: center;
        position: relative;
        cursor: pointer;
        padding: 0 20px;
        .bd {
          width: 160px;
          height: 2px;
          position: absolute;
          bottom: 0px;
          background: #5e58fb;
        }
      }
    }
    .tab_box {
      width: 740px;
      margin: 40px auto;
      .tab_box_item {
        color: #313051;
        font-size: 18px;
        display: none;
        line-height: 30px;
      }
      .hide {
        display: block;
      }
      p {
        color: #313051;
        font-size: 16px;
        margin-top: 10px;
      }
      .t {
        padding-left: 340px;
        height: 240px;
      }

      .t1 {
        background: url(~@/assets/image/t1.png) no-repeat;
        background-size: 300px 178px;
      }

      .t2 {
        background: url(~@/assets/image/t2.png) no-repeat;
        background-size: 300px 178px;
      }

      .t3 {
        background: url(~@/assets/image/t3.png) no-repeat;
        background-size: 300px 178px;
      }

      .t4 {
        background: url(~@/assets/image/t4.png) no-repeat;
        background-size: 300px 178px;
      }

      .t5 {
        background: url(~@/assets/image/t5.png) no-repeat;
        background-size: 300px 178px;
      }
    }
  }
}
#sol {
  .name {
    color: #313051;
    font-size: 30px;
    text-align: center;
  }
  .sol_box {
    display: flex;
    flex-wrap: wrap;
    li:hover {
      box-shadow: 0 2px 20px 0 rgba(228, 228, 228, 0.5);
      border-color: #fff;
    }
    li {
      transition: all 0.5s ease 0s;
      border-radius: 8px;
      overflow: hidden;
      width: 600px;

      text-align: center;
      margin: 40px 0;
      cursor: pointer;
      padding: 40px 100px;

      img {
        display: block;
        margin: 0 auto;
      }
      p {
        font-size: 20px;
        color: #313051;
        margin: 10px 0;
      }
      div {
        font: 15px/25px "微软雅黑";
        height: 75px;
      }
      a:hover {
        background-color: #5751fb;
        border-color: #5751fb;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;
      }
      a {
        display: block;
        line-height: 38px;
        padding: 0 25px;
        border: 1px solid #5751fb;
        color: #5751fb;
        transition: all 0.5s ease 0s;
        font-size: 18px;
        border-radius: 5px;
        width: 170px;
        margin: 40px auto 0 auto;
      }
    }
  }
}

#coop {
  margin-top: 40px;
  .name {
    color: #313051;
    font-size: 30px;
    text-align: center;
    margin-bottom: 40px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      width: 18%;

      padding: 1%;
      img {
        transition: all 0.5s ease 0s;
        max-width: 100% !important;
        height: auto;
      }
      img:hover {
        box-shadow: 0 2px 20px 0 rgba(228, 228, 228, 0.6);
        border-color: #fff;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
