import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  { path: '/login', name: 'Login', component: () => import('../views/login/Login.vue') },//登录
  { path: '/login_wx', name: 'Login_wx', component: () => import('../views/login/Login_wx.vue') },//微信登录
  { path: '/login_phone', name: 'Login_phone', component: () => import('../views/login/Login_phone.vue') },//手机登录
  { path: '/template', name: 'Template', component: () => import('../views/template/Template.vue') },//简历模板
  { path: '/template/:id', name: 'Tempdeil', component: () => import('../views/template/Temdeil.vue') },//简历模板详情
  { path: '/diag', name: 'Diag', component: () => import('../views/diag/Diag.vue') },//简历诊断


  { path: '/personal', name: 'Personal', component: () => import('../views/personal/Personal.vue'), },//个人中心/基本信息
  { path: '/headimg', name: 'Headimg', component: () => import('../views/personal/Headimg.vue') },//个人中心/设置头像
  { path: '/phone', name: 'Phone', component: () => import('../views/personal/Phone.vue') },//个人中心/手机绑定
  { path: '/pwd', name: 'Pwd', component: () => import('../views/personal/Pwd.vue') },//个人中心/修改密码
  { path: '/order', name: 'Order', component: () => import('../views/personal/Order.vue') },//个人中心/我的订单
  { path: '/orderdeil/:id', name: 'Orderdeil', component: () => import('../views/personal/Orderdeil.vue') },//个人中心/订单详情
  { path: '/resume', name: 'Resume', component: () => import('../views/personal/Resume.vue') },//个人中心/简历诊断
  { path: '/myresume', name: 'Myresume', component: () => import('../views/personal/Myresume.vue') },//个人中心/简历诊断
  { path: '/register', name: 'register', component: () => import('../views/register/index.vue') },//个人中心/注册
  { path: '/forget', name: 'forget', component: () => import('../views/login/forget.vue') },//忘记密码


  { path: '/post', name: 'post', component: () => import('../views/news/news copy.vue') },//新闻
  { path: '/new', name: 'new', component: () => import('../views/news/news.vue') },//新闻/
  { path: '/news/:id.html', name: 'news', component: () => import('../views/news/newsdeil.vue') },//新闻/新闻详情
  { path: '/list2.html', name: 'list2', component: () => import('../views/news/list1/index.vue') },//新闻/面试辅导
  { path: '/list3.html', name: 'list3', component: () => import('../views/news/list2/index.vue') },//新闻/简历优化
  { path: '/list4.html', name: 'list4', component: () => import('../views/news/list3/index.vue') },//新闻/工作推荐
  { path: '/postdeil/:id.html', name: 'postdeil', component: () => import('../views/news/list4/index.vue') },//新闻/全部文章

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }

})

export default router
